// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-checkout-payment-js": () => import("./../../../src/pages/checkout/payment.js" /* webpackChunkName: "component---src-pages-checkout-payment-js" */),
  "component---src-pages-checkout-result-js": () => import("./../../../src/pages/checkout/result.js" /* webpackChunkName: "component---src-pages-checkout-result-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-booking-js": () => import("./../../../src/templates/booking.js" /* webpackChunkName: "component---src-templates-booking-js" */),
  "component---src-templates-experience-js": () => import("./../../../src/templates/experience.js" /* webpackChunkName: "component---src-templates-experience-js" */),
  "component---src-templates-intermediary-register-js": () => import("./../../../src/templates/intermediary-register.js" /* webpackChunkName: "component---src-templates-intermediary-register-js" */)
}


import './src/styles/styles.scss';
import Popper from 'popper.js/dist/popper.js';

window.$ = require( "jquery" );
window.Popper = Popper;
require('bootstrap/dist/js/bootstrap.js');

window.addDataLayer = (function(value){
    window.dataLayer = window.dataLayer || [];
    if (window.dataLayer){
        window.dataLayer.push(value);
    } else {
        console.log("dataLayer is undefined");
    }
});

window.showBrand = true;

window.$(window).scroll(function() {
    if(window.$("nav.sticky-top").offset() !== undefined) {
        if ((window.$("nav.sticky-top").offset().top > 75 && window.$(window).width() >= 992) || 
            (window.$("nav.sticky-top").offset().top > 15 && window.$(window).width() < 992)) {
            window.$(".navbar-brand>div").fadeOut();
            window.showBrand = false;
        } else {
            window.$(".navbar-brand>div").fadeIn();
            window.showBrand = true;
        }
    }
});

export const onRouteUpdate = ({ location }) => {

    window.addDataLayer({
        "page_name": location.pathname,
        "page_type": 'content',
        "page_language": location.pathname.split("/")[1]
    });

    window.addDataLayer({ event: `gatsbyRouteChange` });
};
